.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 90vw;
	margin: auto;
}

.container>* {
	padding: 15px 0px;
}

.container>img {
	max-width: 100%;
}