[role="menu"] *:focus {
	outline: none;
}

.notEditMode .editable {
	outline: transparent 2px dashed;
	outline-offset: 6px;
	z-index: 2;
	transition: outline ease-in-out 200ms;
}

.notEditMode .editableNoOffset {
	outline: transparent 2px dashed;
	z-index: 2;
	transition: outline ease-in-out 200ms;
}

.editMode .editable {
	outline: gray 2px dashed;
	outline-offset: 6px;
	z-index: 2;
	transition: outline ease-in-out 200ms;
}

.editMode .editableNoOffset {
	outline: gray 2px dashed;
	z-index: 2;
	transition: outline ease-in-out 200ms;
}

.editMode .editable:hover {
	outline: #3F51B5 2px dashed;
	outline-offset: 6px;
	z-index: 2;
}

.editMode .editableNoOffset:hover {
	outline: #3F51B5 2px dashed;
	z-index: 2;
}